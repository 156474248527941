import { setCookie } from "@/utils/cookies";

const getAbTestCookies = (): Record<string, string> => {
  //TODO: Handle isomorphic cookies
  if (typeof document === "undefined") {
    return {};
  }
  const abTestsValues = {};
  // Match cookies starting with 'ab-' up to the '=' sign, capture cookie name with and without '='
  const matchRegex = RegExp(/(^ab-([a-zA-Z-]*))=/);
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(";");
  for (let i = 0; i < cookiesArray.length; i++) {
    let currentCookie = cookiesArray[i];
    while (currentCookie.startsWith(" ")) {
      currentCookie = currentCookie.substring(1);
    }
    const matchArray = currentCookie.match(matchRegex);
    if (matchArray && matchArray.length >= 3) {
      // matchArray[0] = Cookie name with the '=' sign
      // matchArray[1] = Cookie name without the '=' sign
      // matchArray[2] = Cookie name without the '=' sign and without the ab- prefix
      abTestsValues[matchArray[2]] = currentCookie.substring(
        matchArray[0].length,
        currentCookie.length
      );
    }
  }

  return abTestsValues;
};

const persistAbTestValue = (
  testName: string,
  testValue: unknown,
  persistDurationDays = 30
) => {
  setCookie(`ab-${testName}`, JSON.stringify(testValue), persistDurationDays);
};

export { getAbTestCookies, persistAbTestValue };
