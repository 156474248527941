import { getKeycloakInstance, SSRAuthClient } from "@react-keycloak-fork/ssr";
import { MergedDisponibility } from "@/interfaces/disponibility";
import { DisponibilitiesQueryParams, RideType } from "@/interfaces/searchBlock";
import { DropoffOption, MergedCategory } from "@/interfaces/category";
import { VehicleType } from "@/components/common/searchBlock/children/vehicleTypeSelection/VehicleTypeSelection";
import { defaultCategoryImage } from "@/components/pages/reservation/categories/agencyOption/defaultImages";
import { addBasePathToStrapiMedia, StrapiImage } from "@/utils/strapi";
import { setCookie } from "@/utils/cookies";
import { getAbTestCookies } from "@/utils/abTestingService";
import {
  CategoriesImages,
  CheckoutStepParams,
  InsiderProduct,
  InsiderType,
  PageProps,
  RACKeycloakToken,
  RideTypeForAnalytics,
  Rudderanalytics,
  RudderOptions,
  RudderProductSpec,
  RudderUserTraits,
  UserSearchParams,
} from "@/interfaces/analytics";
import { EmailData } from "@/components/pages/additionalOptions/helpers/sendConfirmationEmail";
import { FilterCategory } from "@/components/pages/reservation/CategoriesWithReactQueryProvider";
import { AgencyB2B } from "@/strapiQuery/agenciesB2B";
import {
  ExtendContractPaymentInformation,
  OptionsPaymentInformations,
} from "@/interfaces/contract";

export declare const rudderanalytics: Rudderanalytics;
export declare const Insider: InsiderType;
export declare const criteo_q:
  | Array<Record<string, unknown>>
  | {
      push: (event: unknown) => void;
      [key: string]: unknown;
    };

const getStartEndAgencies = (
  disponibility: MergedDisponibility,
  selectedDropoffOption: DropoffOption
) => {
  return {
    startAgency: `${disponibility.pickupAgency.code}]] ${disponibility.pickupAgency.name}`,
    endAgency: `${selectedDropoffOption.agency.code}]] ${selectedDropoffOption.agency.name}`,
  };
};

class TrackingService {
  private static _rudderanalytics: Rudderanalytics;
  private static _insider: InsiderType;

  private static log = (...args: unknown[]) => {
    console.log(args);

    return;
  };

  public static get rudderanalytics() {
    if (
      // If we've disabled the Rudder SDK and we're not in a production environment
      // Just log the event to the console
      typeof rudderanalytics === "undefined" &&
      process.env.NODE_ENV !== "production"
    ) {
      TrackingService._rudderanalytics = {
        identify: TrackingService.log,
        page: TrackingService.log,
        track: TrackingService.log,
        reset: TrackingService.log,
        group: TrackingService.log,
        alias: TrackingService.log,
      };
    } else {
      this._rudderanalytics = rudderanalytics;
    }

    return TrackingService._rudderanalytics;
  }

  public static get Insider() {
    if (
      // If we've disabled the Insider and we're not in a production environment
      // Just log to the console
      typeof Insider === "undefined" &&
      process.env.NODE_ENV !== "production"
    ) {
      TrackingService._insider = {
        eventManager: {
          dispatch: TrackingService.log,
        },
        track: TrackingService.log,
      };
    } else if (typeof Insider === "undefined") {
      TrackingService._insider = null;
    } else {
      TrackingService._insider = Insider;
    }

    return TrackingService._insider;
  }

  public static get siteType() {
    return /iPad/.test(navigator.userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent
        )
      ? "m"
      : "d";
  }

  static refreshInsider() {
    TrackingService.Insider?.eventManager.dispatch(
      "init-manager:re-initialize"
    );
  }

  private static isUserConnected(): boolean {
    if (process.env.NODE_ENV === "development") {
      return false;
    }
    // TypeScript complains we must pass an argument to getKeycloakInstance()
    // but here we need to retrieve the already-configured instance so we pass no argument
    // See docs here: https://github.com/react-keycloak/react-keycloak/blob/master/packages/ssr/README.md#external-usage-advanced
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const keycloak: SSRAuthClient | undefined = getKeycloakInstance();

    return keycloak?.authenticated ?? false;
  }

  static identifyUser() {
    // TypeScript complains we must pass an argument to getKeycloakInstance()
    // but here we need to retrieve the already-configured instance so we pass no argument
    // See docs here: https://github.com/react-keycloak/react-keycloak/blob/master/packages/ssr/README.md#external-usage-advanced
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const keycloak: SSRAuthClient | undefined = getKeycloakInstance();
    const parsedToken: RACKeycloakToken | undefined = keycloak?.tokenParsed as
      | RACKeycloakToken
      | undefined;
    const userId = keycloak?.subject;

    if (userId !== "" && typeof userId !== "undefined" && parsedToken) {
      const traits =
        TrackingService.formatRudderTraitsFromKeycloakToken(parsedToken);
      TrackingService.rudderanalytics.identify(userId, traits);
      TrackingService.rudderanalytics.track("Login", {
        category: "User",
        label: "Login",
      });

      if (TrackingService.Insider) {
        window.insider_object = window.insider_object ?? {};
        window.insider_object.user = {
          uuid: userId,
          gender: parsedToken.civility,
          gdpr_optin: true,
          name: parsedToken.given_name,
          surname: parsedToken.family_name,
          username: parsedToken.preferred_username,
          email: parsedToken.email,
          language: navigator.language,
          custom: {
            email_verified: parsedToken.email_verified,
          },
        };
        TrackingService.refreshInsider();
      }
    }
  }

  private static formatRudderTraitsFromKeycloakToken(
    token: RACKeycloakToken
  ): RudderUserTraits {
    const traits: RudderUserTraits = {
      email: token.email,
      firstName: token.given_name,
      lastName: token.family_name,
      fullName: token.name,
      civility: token.civility,
    };

    return traits;
  }

  static logoutUser() {
    TrackingService.rudderanalytics.reset();
    if (TrackingService.Insider) {
      window.insider_object = window.insider_object ?? {};
      window.insider_object.user = {};
      TrackingService.refreshInsider();
    }
  }

  private static getAbTests() {
    return {
      abTests: JSON.stringify({
        ...getAbTestCookies(),
      }),
    };
  }

  static trackPageView(
    category?: string,
    name?: string,
    pageProps?: PageProps,
    options?: RudderOptions
  ) {
    TrackingService.rudderanalytics.page(category, name, pageProps, options);
  }

  private static buildSearchParams(
    disponibilitiesQueryParams: DisponibilitiesQueryParams
  ): UserSearchParams {
    return {
      formAgency: disponibilitiesQueryParams.pickupDescription,
      formAgencyReturn:
        disponibilitiesQueryParams.RideType === RideType.ONE_WAY
          ? disponibilitiesQueryParams.dropoffDescription
          : disponibilitiesQueryParams.pickupDescription,
      formDateGo: disponibilitiesQueryParams.PickupDate,
      formDateReturn: disponibilitiesQueryParams.DropoffDate,
      formType: disponibilitiesQueryParams.VehicleType,
      withoutLicense: disponibilitiesQueryParams.IsOnlyWithoutLicense ?? false,
      formJourney: RideTypeForAnalytics[disponibilitiesQueryParams.RideType],
      isAgency: disponibilitiesQueryParams.isAgency,
      ...TrackingService.getAbTests(),
    };
  }

  static trackUserSearch(
    disponibilitiesQueryParams: DisponibilitiesQueryParams
  ) {
    TrackingService.rudderanalytics.track("Search", {
      category: "User",
      label: "Search",
      ...TrackingService.buildSearchParams(disponibilitiesQueryParams),
    } as UserSearchParams);

    TrackingService.Insider?.track("events", [
      {
        event_name: "Search",
        event_params: TrackingService.buildSearchParams(
          disponibilitiesQueryParams
        ),
      },
    ]);
  }

  private static buildProductObject(
    result: MergedDisponibility,
    query: { RideType: RideType; VehicleType: VehicleType },
    category: MergedCategory,
    categoryImages: StrapiImage[],
    rank: number,
    currency = "EUR"
  ): RudderProductSpec {
    const { startAgency, endAgency } = getStartEndAgencies(
      result,
      category.dropoffOptions[0]
    );

    return {
      product_id: category.id,
      name: `${query.VehicleType} ${category.vehicleLabel ?? ""} ${
        RideTypeForAnalytics[query.RideType]
      }`,
      category: category.vehicleLabel,
      image_url: addBasePathToStrapiMedia(categoryImages[0]),
      price: category.dropoffOptions[0].price.finalPrice,
      currency,
      agencyPosition: result.rank,
      position: rank,
      variant: query.VehicleType,
      brand: category.vehicleExample,
      service: RideTypeForAnalytics[query.RideType],
      pickupAgency: result.pickupAgency.name,
      formDateGo: result.pickupDate,
      formDateReturn: category.dropoffOptions[0].dropoffDate,
      formAgency: startAgency,
      formAgencyReturn: endAgency,
      formKm: result.selectedKilometer,
      pickupDistance: result.pickupAgency.distance,
      dropoffDistance: category.dropoffOptions[0].agency.distance,
      motor: category.motorizationTypeLabel,
      transmission: category.gearboxTypeLabel,
      isGuaranteedCat: category.isGuaranteedCat,
      isSelfService: category.isSelfService,
    };
  }

  private static buildProductList(
    results: MergedDisponibility[],
    query: DisponibilitiesQueryParams,
    categoriesImages: CategoriesImages,
    currency = "EUR"
  ): RudderProductSpec[] {
    let rank = 0;

    return results
      .map<RudderProductSpec[]>((result: MergedDisponibility) => {
        return result.categories.map<RudderProductSpec>((category) => {
          rank += 1;

          const formattedCode =
            category.categoryCode + "-" + result.pickupAgency.companyRegionCode;

          const categoryImages = categoriesImages[formattedCode] ??
            categoriesImages[category.categoryCode + "-DEFAULT"] ?? [
              defaultCategoryImage,
            ];

          return TrackingService.buildProductObject(
            result,
            query,
            category,
            categoryImages,
            rank,
            currency
          );
        });
      })
      .flat();
  }

  private static buildInsiderProduct(
    product: RudderProductSpec,
    currency = "EUR"
  ): InsiderProduct {
    return {
      id: product.product_id,
      name: product.name,
      taxonomy: [product.variant, product.category, product.brand] as string[],
      currency,
      unit_price: product.price,
      unit_sale_price: product.price,
      url: window.location.href,
      product_image_url: product.image_url ?? "",
      custom: {
        service: product.service,
        startDate: product.formDateGo,
        pickupAgency: product.pickupAgency,
        agencyPosition: product.agencyPosition,
        position: product.position,
        endDate: product.formDateReturn,
        startLocation: product.formAgency,
        endLocation: product.formAgencyReturn,
        formKm: product.formKm,
        pickupDistance: product.pickupDistance,
        dropoffDistance: product.dropoffDistance,
        motor: product.motor,
        transmission: product.transmission,
      },
    };
  }

  private static buildCheckoutStepParams(
    query: DisponibilitiesQueryParams
  ): CheckoutStepParams {
    return {
      ...TrackingService.buildSearchParams(query),
      category: query.VehicleType,
      resultPageVersion: "cube",
      isUserConnectedBeforeCheckout: TrackingService.isUserConnected(),
    };
  }

  static trackGuaranteedCatResults(
    results: MergedDisponibility[],
    query: DisponibilitiesQueryParams,
    categoriesImages: CategoriesImages,
    list_id = "Véhicules à proximité",
    currency = "EUR"
  ) {
    const products = TrackingService.buildProductList(
      results,
      query,
      categoriesImages,
      currency
    );
    TrackingService.rudderanalytics.track("Product List Viewed", {
      list_id,
      ...TrackingService.buildCheckoutStepParams(query),
      products,
    });

    if (TrackingService.Insider) {
      window.insider_object = window.insider_object ?? {};
      if (window.insider_object.listing?.items) {
        const insiderProducts = products.map<InsiderProduct>((p) =>
          TrackingService.buildInsiderProduct(p, currency)
        );
        window.insider_object.listing.items =
          window.insider_object.listing.items.concat(insiderProducts);
        TrackingService.refreshInsider();
      }
    }

    if (typeof criteo_q !== "undefined") {
      criteo_q.push(
        { event: "setAccount", account: 105659 },
        { event: "setSiteType", type: TrackingService.siteType },
        {
          event: "viewList",
          item: products.map((product) => product.product_id),
        }
      );
    }
  }

  static trackSearchResults(
    results: MergedDisponibility[],
    query: DisponibilitiesQueryParams,
    categoriesImages: CategoriesImages,
    list_id = "search",
    currency = "EUR"
  ) {
    setCookie("resultPageVersion", "cube", 20);

    const products = TrackingService.buildProductList(
      results,
      query,
      categoriesImages,
      currency
    );
    TrackingService.rudderanalytics.track("Product List Viewed", {
      list_id,
      ...TrackingService.buildCheckoutStepParams(query),
      products,
    });

    TrackingService.rudderanalytics.track("Checkout Step Viewed", {
      step: 1,
      ...TrackingService.buildCheckoutStepParams(query),
    });

    if (TrackingService.Insider) {
      window.insider_object = window.insider_object ?? {};
      window.insider_object.listing = {
        items: products.map<InsiderProduct>((p) =>
          TrackingService.buildInsiderProduct(p, currency)
        ),
      };
      TrackingService.refreshInsider();
    }

    if (typeof criteo_q !== "undefined") {
      criteo_q.push(
        { event: "setAccount", account: 105659 },
        { event: "setSiteType", type: TrackingService.siteType },
        {
          event: "viewSearch",
          checkin_date: query.PickupDate,
          checkout_date: query.DropoffDate,
        },
        {
          event: "viewList",
          item: products.map((product) => product.product_id),
        }
      );
    }
  }

  static trackSearchFiltered(
    results: MergedDisponibility[],
    query: DisponibilitiesQueryParams,
    categoriesImages: CategoriesImages,
    categoriesFilter: FilterCategory[],
    automaticFilter: boolean,
    selfServiceFilter: boolean,
    electricFilter: boolean,
    selectedTrunkButton: number | null | undefined = null,
    list_id = "search",
    currency = "EUR"
  ) {
    const products = TrackingService.buildProductList(
      results,
      query,
      categoriesImages,
      currency
    );

    const filteredCats = categoriesFilter.map((cf) => cf.code).join(",");

    rudderanalytics.track(
      "Product List Filtered",
      {
        list_id,
        filters: [
          {
            type: "categories",
            value: filteredCats,
          },
          {
            type: "automaticFilter",
            value: automaticFilter,
          },
          {
            type: "selfServiceFilter",
            value: selfServiceFilter,
          },
          {
            type: "electricFilter",
            value: electricFilter,
          },
          {
            type: "selectedTrunkButton",
            value: selectedTrunkButton,
          },
        ],
        products,
      },
      {
        integrations: {
          All: true,
          GA4: false,
        },
      }
    );

    rudderanalytics.track(
      "product_list_filtered_ga4",
      {
        list_id,
        filteredCats,
        automaticFilter,
        selfServiceFilter,
        electricFilter,
        selectedTrunkButton,
        filteredProductsCount: products.length,
      },
      {
        integrations: {
          All: false,
          GA4: true,
        },
      }
    );

    if (TrackingService.Insider) {
      window.insider_object = window.insider_object ?? {};
      window.insider_object.listing = {
        items: products.map<InsiderProduct>((p) =>
          TrackingService.buildInsiderProduct(p, currency)
        ),
      };
      TrackingService.refreshInsider();
    }
  }

  static trackProductViewed(
    category: MergedCategory,
    disponibility: MergedDisponibility,
    rideType: RideType,
    vehicleType: VehicleType,
    images: StrapiImage[],
    currency = "EUR"
  ) {
    const product: RudderProductSpec = TrackingService.buildProductObject(
      disponibility,
      { RideType: rideType, VehicleType: vehicleType },
      category,
      images,
      category.rank,
      currency
    );

    TrackingService.rudderanalytics.track("Product Viewed", {
      ...product,
      ...TrackingService.getAbTests(),
    } as RudderProductSpec);

    if (TrackingService.Insider) {
      window.insider_object = window.insider_object ?? {};
      window.insider_object.page = {
        type: "Product",
      };
      window.insider_object.product = TrackingService.buildInsiderProduct(
        product,
        currency
      );
      TrackingService.refreshInsider();
    }
  }

  static trackAddToCart(
    category: MergedCategory,
    disponibility: MergedDisponibility,
    rideType: RideType,
    vehicleType: VehicleType,
    images: StrapiImage[]
  ) {
    if (category.isGuaranteedCat) {
      setCookie("ab-hasSelectedGuranteedCat", "true", 20);
    } else {
      setCookie("ab-hasSelectedGuranteedCat", "false", 20);
    }
    TrackingService.rudderanalytics.track("Product Added", {
      ...TrackingService.buildProductObject(
        disponibility,
        { RideType: rideType, VehicleType: vehicleType },
        category,
        images,
        category.rank
      ),
      ...TrackingService.getAbTests(),
    } as RudderProductSpec);
  }

  static trackCtaClick(label: string, rest?: Record<string, unknown>) {
    TrackingService.rudderanalytics.track("Click", {
      category: "CTA",
      label,
      ...TrackingService.getAbTests(),
      ...rest,
    });
  }

  static trackUserTiming(category: string, time: number) {
    TrackingService.rudderanalytics.track("Timing", {
      category,
      label: time,
      ...TrackingService.getAbTests(),
    });
  }

  static trackAdditionalOptions(
    data: EmailData,
    agencyCode: string,
    companyRegionCode: string,
    currency = "EUR"
  ) {
    TrackingService.rudderanalytics.track("AdditionalOptions", {
      label: data.bookingId,
      value: data.optionsPrice,
      additionalOptions: data.optionsChoices.reduce((acc, curr, idx) => {
        if (idx !== 0) {
          acc += " -- ";
        }
        acc += `${curr.quantity} x ${curr.title}_${curr.id}`;

        return acc;
      }, ""),
      agencyCode: `${companyRegionCode}${agencyCode}`,
      additionalInsuranceChoice: data.insuranceChoice
        ? `${data.insuranceChoice.title}_${data.insuranceChoice.id}`
        : null,
      currency,
      ...TrackingService.getAbTests(),
    });
  }

  static trackLeadFormPro(agencySelected: AgencyB2B) {
    TrackingService.rudderanalytics.track("lead_form_pro", {
      formAgency: `${agencySelected.agencyId}]] ${agencySelected.agencyName}`,
      ...TrackingService.getAbTests(),
    });
  }
  static trackExtendPayment(
    extendPaymentInformation: ExtendContractPaymentInformation
  ) {
    if (typeof extendPaymentInformation === "undefined") {
      console.error("Received no extendPaymentInformation");

      return;
    }
    TrackingService.rudderanalytics.track("extend_payment", {
      label: extendPaymentInformation.information.id,
      value: extendPaymentInformation.totalAmount,
      formAgency: `${extendPaymentInformation.agencyId}]] ${extendPaymentInformation.agencyName}`,
      formAgencyReturn: extendPaymentInformation.information.dropoffAgencyId,
      formDateGo: extendPaymentInformation.information.pickupDate,
      formDateReturn: extendPaymentInformation.information.dropoffDate,
      // formType: extendPaymentInformation.information.category?.vehicleLabel,
      ...TrackingService.getAbTests(),
    });
  }

  static trackOptionsPayment(
    optionsPaymentInformation: OptionsPaymentInformations
  ) {
    if (typeof optionsPaymentInformation === "undefined") {
      console.error("Received no optionsPaymentInformation");

      return;
    }
    TrackingService.rudderanalytics.track("options_payment", {
      label: optionsPaymentInformation.information.bookingId,
      value: optionsPaymentInformation.totalAmount,
      additionalOptions: optionsPaymentInformation.information.options
        .map((option) => `${option.quantity} x ${option.title}`)
        .join(" -- "),
      ...TrackingService.getAbTests(),
    });
  }
}

export default TrackingService;
