export const STRAPI_URL = process.env.NEXT_PUBLIC_STRAPI_URL ?? "";

export type StrapiEntity<T> = {
  data: {
    id: string;
    attributes: T;
  };
};

export type StrapiCollection<T> = {
  data: {
    id: string;
    attributes: T;
  }[];
};

export type StrapiImage = {
  url: string;
  alternativeText: string;
};

export type Cta = {
  label: string;
  url: string;
};

export const formatStrapiCollection = <T>(
  strapiCollection: StrapiCollection<T>
): T[] => strapiCollection.data.map((entity) => entity.attributes);

export const formatStrapiEntity = <T>(strapiEntity: StrapiEntity<T>): T =>
  strapiEntity.data.attributes;

export const addBasePathToStrapiMedia = ({ url }: StrapiImage) =>
  url.startsWith("/")
    ? `${process.env.NEXT_PUBLIC_STRAPI_URL ?? ""}${url}`
    : url;

const fetchStrapiData = async (path: string, query: string) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI_URL ?? ""}/api/${path}`;
  const formattedQuery = query ? `?${query}` : "";

  return fetch(`${url}${formattedQuery}`).then((response) => response.json());
};

export const getStrapiEntity = <T>(path: string, query: string) =>
  fetchStrapiData(path, query).then((json: StrapiEntity<T>) =>
    formatStrapiEntity(json)
  );

export const getStrapiCollection = <T>(path: string, query: string) =>
  fetchStrapiData(path, query).then((json: StrapiCollection<T>) =>
    formatStrapiCollection(json)
  );
