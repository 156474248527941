import { MantineThemeOverride } from "@mantine/core";

export const mantineTheme: MantineThemeOverride = {
  colors: {
    "ocean-blue": ["#0069b4"],
    "error-red": ["#FF0000"],
    gunmetal: ["#3f4756"],
  },
  breakpoints: {
    sm: 820,
    md: 1024,
    lg: 1320,
  },
};
