import { KeycloakTokenParsed } from "keycloak-js";
import { VehicleType } from "@/components/common/searchBlock/children/vehicleTypeSelection/VehicleTypeSelection";
import { StrapiImage } from "@/utils/strapi";

type RudderParams = (
  | string
  | undefined
  | PageProps
  | RudderOptions
  | (() => void)
)[];
export type PageProps = {
  path?: string;
  referrer?: string;
  search?: string;
  title?: string;
  url?: string;
  racWebVersion?: string;
};
export type RudderUserTraits = {
  email: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  civility?: string;
  // [key: string]: string | number | boolean | undefined | object;
};
export type RudderOptions = {
  integrations?: {
    [key: string]: boolean;
  };
  anonymousId?: string;
  originalTimestamp?: string;
  context?: {
    [key: string]: string | boolean | number | object;
  };
};
export interface Rudderanalytics {
  page: (
    category?: string,
    name?: string,
    props?: PageProps,
    options?: RudderOptions
  ) => void;
  alias: (...args: RudderParams) => void;
  group: (...args: RudderParams) => void;
  identify: (
    userId: string,
    traits?: RudderUserTraits,
    options?: RudderOptions
  ) => void;
  reset: () => void;
  track: (
    eventAction: string,
    eventProps?: {
      [key: string]: string | number | boolean | undefined | object | null;
    },
    options?: RudderOptions,
    callback?: () => void
  ) => void;
}
export type RACKeycloakToken =
  | KeycloakTokenParsed & {
      given_name: string;
      family_name: string;
      name: string;
      email: string;
      civility?: string;
      preferred_username: string;
      email_verified: boolean;
    };
export type RudderProductSpec = {
  product_id: string;
  name: string;
  category?: string;
  price: number;
  image_url?: string;
  agencyPosition: number;
  position: number;
  currency: string;
  variant: string;
  brand?: string;
  service: string;
  pickupAgency: string;
  formDateGo: string;
  formDateReturn: string;
  formAgency: string;
  formAgencyReturn: string;
  formKm: number;
  pickupDistance: number;
  dropoffDistance: number;
  motor?: string | null;
  transmission?: string | null;
  isGuaranteedCat: boolean;
  isSelfService: boolean;
};
export type UserSearchParams = {
  formAgency: string;
  formAgencyReturn: string;
  formDateGo: string;
  formDateReturn: string;
  formType: VehicleType;
  withoutLicense: boolean;
  formJourney: RideTypeForAnalytics;
  isAgency: boolean;
  abTests: string;
};
export type CheckoutStepParams = {
  category: VehicleType;
  formAgency: string;
  formAgencyReturn: string;
  formDateGo: string;
  formDateReturn: string;
  formJourney: RideTypeForAnalytics;
  formType: VehicleType;
  withoutLicense: boolean;
  resultPageVersion: "cube";
  abTests: string;
  isUserConnectedBeforeCheckout: boolean;
};
type Nullable<T> = T | null | undefined;
interface InsiderInterface {
  eventManager: {
    dispatch: (arg0: string) => void;
  };
  track: (type: string, data: object[]) => void;
}
export type InsiderType = Nullable<InsiderInterface>;

declare global {
  interface Window {
    insider_object?: {
      page?: {
        type: string;
        breadcrumbs?: string[];
      };
      user?:
        | {
            uuid: string;
            gender?: string;
            gdpr_optin: true;
            name?: string;
            surname?: string;
            username?: string;
            email: string;
            language?: string;
            custom?: Record<string, unknown>;
          }
        | Record<string, never>;
      listing?: InsiderListing;
      product?: InsiderProduct;
    };
  }
}
export type InsiderProduct = {
  id: string;
  name: string;
  taxonomy: string[];
  currency: string;
  unit_price: number;
  unit_sale_price: number;
  product_image_url: string;
  url: string;
  custom?: Record<string, unknown>;
};
type InsiderListing = {
  items: InsiderProduct[];
};
export enum RideTypeForAnalytics {
  RoundTrip = "trajet courte durée",
  OneWay = "trajet simple",
}
export type CategoriesImages = {
  [name: string]: StrapiImage[] | undefined;
};
