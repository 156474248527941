type RentACarColoredIconProps = {
  withWhiteBorder?: boolean;
};

export const RentACarColoredIcon = ({
  withWhiteBorder = true,
}: RentACarColoredIconProps) => (
  <svg viewBox="0 0 175.388 49.995">
    <defs>
      <clipPath id="hyfaxubisa">
        <path
          data-name="Rectangle 1170"
          style={{ fill: "none" }}
          d="M0 0h175.388v49.995H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Groupe 9442" style={{ clipPath: "url(#hyfaxubisa)" }}>
      {withWhiteBorder && (
        <path
          data-name="Tracé 11230"
          d="M169 50H0V0h175.389v43.82c0 2.417-2.866 6.175-6.387 6.175"
          style={{ fill: "#fff" }}
        />
      )}

      <path
        data-name="Tracé 11231"
        d="M168.786 49.7H2.7V2.424h172.363v41.05a6.261 6.261 0 0 1-6.277 6.23"
        transform="translate(-1.188 -1.066)"
        style={{ fill: "#0069b4" }}
      />
      <path
        data-name="Tracé 11232"
        d="M31.305 33.449h2.836A2.344 2.344 0 0 0 36.7 31a2.31 2.31 0 0 0-2.562-2.423h-2.833zm-4.131-6.527a1.949 1.949 0 0 1 2.12-2.148h4.847c4.242 0 6.859 1.9 6.859 6.226 0 3.029-2.286 4.764-5.1 5.205l4.683 5.012a1.787 1.787 0 0 1 .551 1.212 2.089 2.089 0 0 1-3.663 1.4l-6.111-7.406h-.054V42.4a2.067 2.067 0 1 1-4.131 0z"
        transform="translate(-11.952 -10.896)"
        style={{ fill: "#fff" }}
      />
      <path
        data-name="Tracé 11233"
        d="M56.476 27.169c0-1.377.688-2.4 2.148-2.4h6.83a1.833 1.833 0 0 1 1.956 1.9 1.864 1.864 0 0 1-1.956 1.9h-4.847v4.021h4.516a1.865 1.865 0 0 1 2.01 1.9 1.9 1.9 0 0 1-2.01 1.9h-4.516v4.186H65.7a1.833 1.833 0 0 1 1.955 1.9 1.864 1.864 0 0 1-1.955 1.9h-7.1a2.011 2.011 0 0 1-2.121-2.065z"
        transform="translate(-24.839 -10.895)"
        style={{ fill: "#fff" }}
      />
      <path
        data-name="Tracé 11234"
        d="M80.91 26.626a1.974 1.974 0 0 1 2.065-2.148 2.57 2.57 0 0 1 1.735.909l8.73 11.65h.06V26.626a2.067 2.067 0 1 1 4.131 0v15.643a1.974 1.974 0 0 1-2.066 2.148 2.636 2.636 0 0 1-1.735-.909L85.1 32h-.055v10.269a2.067 2.067 0 1 1-4.131 0z"
        transform="translate(-35.586 -10.766)"
        style={{ fill: "#fff" }}
      />
      <path
        data-name="Tracé 11235"
        d="M118.922 28.574h-3a1.779 1.779 0 0 1-1.873-1.9 1.844 1.844 0 0 1 1.873-1.9h10.135a1.844 1.844 0 0 1 1.873 1.9 1.779 1.779 0 0 1-1.873 1.9h-3V42.4a2.067 2.067 0 1 1-4.131 0z"
        transform="translate(-50.16 -10.895)"
        style={{ fill: "#fff" }}
      />
      <path
        data-name="Tracé 11236"
        d="M98.09 64.592h-69.2a1.658 1.658 0 0 0 0 3.316h72.435q-1.765-1.666-3.231-3.316"
        transform="translate(-11.976 -28.408)"
        style={{ fill: "#e20613" }}
      />
      <path
        data-name="Tracé 11237"
        d="M232.889 64.592h-57.707q-1.47 1.649-3.231 3.316h60.938a1.658 1.658 0 0 0 0-3.316"
        transform="translate(-75.627 -28.408)"
        style={{ fill: "#e20613" }}
      />
      <path
        data-name="Tracé 11238"
        d="M158.712 18.459a10.907 10.907 0 0 1 11.169 10.626c-.02 9-9.646 16.919-11.169 18.349-1.523-1.43-11.149-9.353-11.169-18.349a10.907 10.907 0 0 1 11.169-10.626"
        transform="translate(-64.894 -8.118)"
        style={{ fill: "#00244d" }}
      />
      <path
        data-name="Tracé 11239"
        d="M201.864 24.183c2.01 0 5.921.661 5.921 3.084a1.749 1.749 0 0 1-1.707 1.845c-1.129 0-1.9-.964-4.214-.964-3.415 0-5.178 2.892-5.178 6.252 0 3.278 1.79 6.086 5.178 6.086 2.314 0 3.25-1.157 4.379-1.157a1.939 1.939 0 0 1 1.818 1.873c0 2.643-4.159 3.25-6.2 3.25-5.591 0-9.474-4.434-9.474-10.135 0-5.728 3.856-10.135 9.474-10.135"
        transform="translate(-84.617 -10.636)"
        style={{ fill: "#fff" }}
      />
      <path
        data-name="Tracé 11240"
        d="M229.689 36.466h5.232l-2.561-7.987h-.055zm-.3-10a3.239 3.239 0 0 1 2.947-2.286 3.116 3.116 0 0 1 2.947 2.286l5.4 14.982a3.209 3.209 0 0 1 .22 1.02 1.835 1.835 0 0 1-1.9 1.817 1.93 1.93 0 0 1-2.01-1.432l-.826-2.589H228.5l-.826 2.561a1.913 1.913 0 0 1-1.983 1.46 1.976 1.976 0 0 1-2.038-1.928 2.353 2.353 0 0 1 .193-.909z"
        transform="translate(-98.369 -10.636)"
        style={{ fill: "#fff" }}
      />
      <path
        data-name="Tracé 11241"
        d="M262.842 33.449h2.837A2.343 2.343 0 0 0 268.24 31a2.308 2.308 0 0 0-2.561-2.423h-2.837zm-4.131-6.527a1.949 1.949 0 0 1 2.121-2.148h4.847c4.241 0 6.857 1.9 6.857 6.224 0 3.029-2.286 4.764-5.095 5.205l4.682 5.012a1.787 1.787 0 0 1 .551 1.212 2.089 2.089 0 0 1-3.663 1.4l-6.111-7.404h-.055V42.4a2.067 2.067 0 1 1-4.131 0z"
        transform="translate(-113.786 -10.896)"
        style={{ fill: "#fff" }}
      />
      <path
        data-name="Tracé 11242"
        d="M156.949 17.728a10.907 10.907 0 0 1 11.169 10.626c-.02 9-9.646 16.919-11.169 18.349-1.523-1.43-11.149-9.353-11.169-18.349a10.908 10.908 0 0 1 11.169-10.626"
        transform="translate(-64.118 -7.797)"
        style={{ fill: "#e20613" }}
      />
      <path
        data-name="Tracé 11243"
        d="M157.54 34.243c0 1.711.9 3.138 2.543 3.138s2.543-1.426 2.543-3.138-.9-3.137-2.543-3.137-2.543 1.426-2.543 3.137m8.794 4.54a1.844 1.844 0 0 1-1.854 1.925 1.917 1.917 0 0 1-1.735-1.189 3.7 3.7 0 0 1-3.185 1.426c-3.233 0-5.728-2.971-5.728-6.7s2.5-6.7 5.728-6.7a3.7 3.7 0 0 1 3.185 1.426 1.917 1.917 0 0 1 1.735-1.188 1.843 1.843 0 0 1 1.854 1.925z"
        transform="translate(-67.658 -12.113)"
        style={{ fill: "#fff" }}
      />
    </g>
  </svg>
);
