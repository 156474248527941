import { StrapiImage } from "@/utils/strapi";

const DEFAULT_CATEGORY_IMAGE_URL =
  process.env.NEXT_PUBLIC_DEFAULT_CATEGORY_IMAGE_URL ?? "";

const DEFAULT_EXTERNAL_AGENCY_MOBILE_IMAGE =
  process.env.NEXT_PUBLIC_DEFAULT_EXTERNAL_AGENCY_MOBILE_IMAGE_URL ?? "";

const DEFAULT_EXTERNAL_AGENCY_DESKTOP_IMAGE =
  process.env.NEXT_PUBLIC_DEFAULT_EXTERNAL_AGENCY_DESKTOP_IMAGE_URL ?? "";

type ExternalAgencyImages = {
  mobile: {
    url: string;
    alternativeText: string;
  };
  desktop: {
    url: string;
    alternativeText: string;
  };
};

export const defaultCategoryImage: StrapiImage = {
  url: DEFAULT_CATEGORY_IMAGE_URL,
  alternativeText: "Default category image.",
};

export const defaultExternalAgencyImages: ExternalAgencyImages = {
  mobile: {
    url: DEFAULT_EXTERNAL_AGENCY_MOBILE_IMAGE,
    alternativeText: "Default external agency mobile image.",
  },
  desktop: {
    url: DEFAULT_EXTERNAL_AGENCY_DESKTOP_IMAGE,
    alternativeText: "Default external agency desktop image.",
  },
};
