import Image from "next/image";
import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import * as Sentry from "@sentry/nextjs";
import { RentACarColoredIcon } from "@/public/icons/RentACarColoredIcon";
import { getBreakpoint } from "@/utils/mantine";
import { routes } from "@/routes/routes";
import error500 from "@/public/images/error500.svg";
import error500Colored from "@/public/images/error500Colored.png";
import error404Colored from "@/public/images/error404Colored.png";
import error404 from "@/public/images/error404.svg";
import styles from "./Error.module.scss";

type ERROR_STATUSES = 404 | 500;

type ErrorProps = {
  resetErrorState?: () => void;
  status?: ERROR_STATUSES;
};

type StaticImageData = {
  src: string;
  height: number;
  width: number;
  placeholder?: string;
};

export const Error = ({ resetErrorState, status = 500 }: ErrorProps) => {
  const theme = useMantineTheme();
  const isTabletOrDesktop = useMediaQuery(
    getBreakpoint(theme.breakpoints.md),
    true
  );
  const { push, asPath } = useRouter();
  const intl = useIntl();

  const errorConfig =
    status === 500
      ? {
          errorImage: error500 as StaticImageData,
          errorImageSize: 172,
          errorImageColored: error500Colored,
          title: "error500Title",
          text: "error500Text",
        }
      : {
          errorImage: error404 as StaticImageData,
          errorImageSize: 456,
          errorImageColored: error404Colored,
          title: "error404Title",
          text: "error404Text",
        };

  Sentry.captureMessage(
    `Error ${status} occured on url ${asPath}`,
    Sentry.Severity.Error
  );

  return (
    <div className={styles.main}>
      <div className={styles.leftBlock}>
        <div className={styles.racIcon}>
          <RentACarColoredIcon withWhiteBorder={false} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.imageColored}>
              <Image
                src={errorConfig.errorImageColored}
                alt=""
                layout="fixed"
                width={41}
                height={41}
                objectFit="contain"
              />
            </div>

            <h1 className={styles.title}>
              {intl.formatMessage({ id: errorConfig.title })}
            </h1>
          </div>
          <p className={styles.text}>
            {intl.formatMessage({ id: errorConfig.text })}
          </p>
        </div>
        <button
          onClick={async () => {
            await push(routes.home);
            resetErrorState?.();
          }}
          className={styles.goBackBtn}
        >
          {intl.formatMessage({ id: "backToHome" })}
        </button>
      </div>

      {isTabletOrDesktop && (
        <div className={styles.rightImageContainer}>
          <Image
            src={errorConfig.errorImage}
            alt=""
            layout="fixed"
            width={errorConfig.errorImageSize}
            height={errorConfig.errorImageSize}
            objectFit="contain"
          />
        </div>
      )}
    </div>
  );
};
