import { useKeycloak } from "@react-keycloak-fork/ssr";
import { useEffect } from "react";

type AuthGuardProps = {
  children: JSX.Element;
  requireAuth?: boolean;
};

export default function AuthGuard({ children, requireAuth }: AuthGuardProps) {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    if (requireAuth && initialized && keycloak) {
      if (!keycloak.authenticated) {
        void keycloak.login();
      }
      sessionStorage.setItem("keycloak_token", keycloak.token ?? "");
      sessionStorage.setItem(
        "keycloak_user_firstname",
        (keycloak.tokenParsed?.given_name as string | undefined) ?? ""
      );
      sessionStorage.setItem(
        "keycloak_user_lastname",
        (keycloak.tokenParsed?.family_name as string | undefined) ?? ""
      );
      sessionStorage.setItem(
        "keycloak_user_email",
        (keycloak.tokenParsed?.email as string | undefined) ?? ""
      );
    }
  }, [requireAuth, initialized, keycloak]);

  if (!requireAuth) {
    return children;
  }

  if (initialized && keycloak && keycloak.authenticated) {
    return children;
  }

  return <div></div>;
}
