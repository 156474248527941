import dayjs from "dayjs";
import "dayjs/locale/fr";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export type LoadDayJSLocaleProps = {
  locale: "fr" | "it";
};

export const loadDayJSLocale = ({ locale }: LoadDayJSLocaleProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale(locale);
};
